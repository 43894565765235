import React, { useEffect } from "react";
import { Card, Col, Layout, Row } from "antd";
import PlayerScreen from "../PlayerScreen";
import { useDispatch, useSelector } from "react-redux";
import { selectPlayerstatsByPlayerId } from "../../redux/selectors/playerstatsSelectors";
import { parseQueryString } from "../../shared-components/queryStringUtils";
import { getMyPlayerIds } from "../../redux/actions/playerProfileActions";
import {
  selectMyPlayerIds,
  selectPlayerProfileLoading,
} from "../../redux/selectors/playerProfileSelectors";
import PlayerDashboardStats from "../playerComponents/PlayerDashboardStats";
import GeneralInfo from "../playerComponents/GeneralInfo";
import NongameStats from "../playerComponents/NongameStats";

const Training = props => {
  const dispatch = useDispatch();
  const myPlayerIds = useSelector(selectMyPlayerIds);
  const loading = useSelector(selectPlayerProfileLoading);
  const playerId = parseQueryString(props.location.search, "playerId").playerId
    ? parseQueryString(props.location.search, "playerId").playerId
    : myPlayerIds[0];
  const playerData = useSelector(selectPlayerstatsByPlayerId(playerId));

  useEffect(() => {
    if (!loading && !playerId && !playerData) {
      dispatch(getMyPlayerIds());
    }
  });

  return (
    <Layout>
      <Layout.Content style={{ paddingBottom: "48px" }}>
        <Row>
          <Col xs={24} lg={12}>
            <GeneralInfo playerId={playerId} />
          </Col>
        </Row>
        <Row>
          <Col xs={24} lg={18}>
            <NongameStats playerId={playerId} />
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default Training;
