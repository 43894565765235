import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getRosterPlayersBySeasonId,
  putRosterPlayers,
} from "../../redux/actions/rosterActions";
import { Button, Col, Layout, message, Space } from "antd";
import { parseQueryString } from "../../shared-components/queryStringUtils";
import {
  selectCurrentSeasonId,
  selectRosterPlayersByRosterId,
  selectRosterTeamsBySeasonId,
} from "../../redux/selectors/rosterSelectors";
import RosterTabs from "./components/RosterTabs";
import RosterModal from "./components/RosterModal";
import PlayerTable from "../../shared-components/PlayerTable";
import { RetweetOutlined, UserAddOutlined } from "@ant-design/icons";
import AddPlayerModal from "./components/AddPlayerModal";
import FlatfileRosterUpload from "./components/FlatfileRosterUpload";

const RosterManage = props => {
  const { rosterId, seasonId, page, sortOrder, sortField, filters } =
    parseQueryString(props.location.search, "rosterId");

  return (
    <Team
      rosterId={rosterId}
      seasonId={seasonId}
      page={page}
      sortOrder={sortOrder}
      sortField={sortField}
      filters={filters}
    />
  );
};

export const Team = ({ rosterId, seasonId }) => {
  const dispatch = useDispatch();

  const currentSeasonIdInStore = useSelector(selectCurrentSeasonId);
  const rosterPlayerData = useSelector(selectRosterPlayersByRosterId(rosterId));
  const rosterTeamData = useSelector(selectRosterTeamsBySeasonId(seasonId));

  const loadingPlayers = useSelector(store => store.rosterData.loadingPlayers);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isRosterModalVisible, setIsRosterModalVisible] = useState(false);
  const [isAddPlayerModalVisible, setAddPlayerModalVisible] = useState(false);

  const updatePlayer = row => {
    updatePlayers([row]);
  };

  const updatePlayers = rows => {
    dispatch(putRosterPlayers(rows));
  };

  useEffect(() => {
    if (seasonId !== currentSeasonIdInStore && !loadingPlayers) {
      dispatch(getRosterPlayersBySeasonId(seasonId));
    }
  });

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "playerName",
      editable: true,
      //width: 100,
    },
    {
      title: "Team",
      dataIndex: "level",
      editable: false,
    },
    {
      title: "Position",
      dataIndex: "position",
      filterable: true,
      editable: true,
    },
    {
      title: "Jersey",
      dataIndex: "jersey",
      editable: true,
    },
    {
      title: "Graduating Class",
      dataIndex: "graduationYear",
      editable: true,
      filterable: true,
    },
  ];

  function updateRosterForSelectedPlayers(rosterId) {
    // fisrt hide modal
    setIsRosterModalVisible(false);

    // rosterId will be null if user hit 'Cancel'
    if (rosterId) {
      // get level for new rosterId
      const level = rosterTeamData.find(r => r.rosterId === rosterId)["level"];

      // selectedRowKeys is already an array of playerIds
      // so we just need to filter rosterPlayerData for those that are included in the selection
      const playersToUpdate = rosterPlayerData
        .filter(r => selectedRowKeys.includes(r.playerId))
        // then map to set the rosterId for those players
        .map(r => {
          return { ...r, oldRosterId: r.rosterId, rosterId, level };
        });

      updatePlayers(playersToUpdate);
      // reset selection
      setSelectedRowKeys([]);
      message.success(`${playersToUpdate.length} moved to ${level} roster!`, 3);
    }
  }

  return (
    <Layout>
      <Layout.Content>
        <h1>Roster Management</h1>
        <RosterTabs rosterId={rosterId} showAll={true} hideScouting={true} />
      </Layout.Content>
      <Layout.Content>
        <Space direction="horizontal">
          <Button type="primary" onClick={() => setIsRosterModalVisible(true)}>
            Move
            <RetweetOutlined style={{ color: "white" }} />
          </Button>
          <Button type="primary" onClick={() => setAddPlayerModalVisible(true)}>
            Add Player
            <UserAddOutlined style={{ color: "white" }} />
          </Button>
          <FlatfileRosterUpload rosterId={rosterId} seasonId={seasonId} />
        </Space>
      </Layout.Content>
      <Col xs={24} lg={18}>
        <PlayerTable
          playerData={rosterPlayerData}
          // only show team column for 'all'
          columns={columns.filter(
            c => c.title !== "Team" || rosterId === "all",
          )}
          loading={loadingPlayers}
          rowSelection={rowSelection}
          updatePlayer={updatePlayer}
          editable={true}
          navBase={"/coaches"}
        />
      </Col>
      <RosterModal
        isVisible={isRosterModalVisible}
        onSelectRoster={updateRosterForSelectedPlayers}
        seasonId={seasonId}
      />
      <AddPlayerModal
        isVisible={isAddPlayerModalVisible}
        rosterId={rosterId}
        closeModal={() => setAddPlayerModalVisible(false)}
        seasonId={seasonId}
      />
    </Layout>
  );
};

export default RosterManage;
