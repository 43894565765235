import { Button, Form, Input, Layout, message, Space, Table } from "antd";
import React, { useState } from "react";
import { useLocation, useNavigate } from "@reach/router";
import * as queryString from "query-string";
import { FBPositionSelect } from "./FBSelects";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { parseQueryString } from "./queryStringUtils";
import { lookupStatLabel } from "./categories_fb";
import { Link } from "@reach/router";
import { GraduatingClassSelect } from "./GraduatingClassSelect";

const { Content } = Layout;

export const sorter = key => (a, b) => {
  const k = key.startsWith("display")
    ? key.substring(7, 8).toLowerCase() + key.substring(8)
    : key;

  if (Number.isInteger(Number(a[k]))) {
    return Number(a[k]) - Number(b[k]);
  } else if (typeof a[k] === "string") {
    return a[k].localeCompare(b[k]);
  } else {
    return a[k] - b[k];
  }
};

const getFilterValuesFromData = (data, fieldName) => {
  if (data) {
    return data
      .map(p => p[fieldName])
      .sort()
      .filter((value, index, self) => self.indexOf(value) === index)
      .map(v => {
        return { text: v, value: v };
      });
  } else {
    return [];
  }
};

const getinputNode = columnType => {
  if (columnType === "position") {
    return <FBPositionSelect />;
  } else if (columnType === "graduationYear") {
    return <GraduatingClassSelect />;
  } else {
    return <Input />;
  }
};

export const EditableCell = ({
  editing,
  dataIndex,
  title,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = getinputNode(dataIndex);

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const PlayerTableWithLocationUpdate = ({
  playerData,
  columns,
  loading,
  rowSelection,
  editable,
  updatePlayer,
  navBase,
  expandable
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { page, pageSize, sortOrder, sortField, filters } = parseQueryString(
    location.search,
  );

  const tableChange = (pagination, filters, sorter, columns) => {
    const queryParams = queryString.parse(location.search);
    const newQueryParams = {
      ...queryParams,
      page: pagination.current,
      pageSize: pagination.pageSize,
      filters: JSON.stringify(filters),
      sortOrder: sorter.order,
      sortField: sorter.field,
    };

    navigate(location.pathname + "?" + queryString.stringify(newQueryParams));
  };

  return (
    <InternalPlayerTable
      playerData={playerData}
      columns={columns}
      loading={loading}
      rowSelection={rowSelection}
      editable={editable}
      updatePlayer={updatePlayer}
      navBase={navBase}
      expandable={expandable}
      page={page}
      pageSize={pageSize}
      sortOrder={sortOrder}
      sortField={sortField}
      filters={filters}
      tableChange={tableChange}
    />
  );
};

const PlayerTableWithStateUpdate = ({
  playerData,
  columns,
  loading,
  rowSelection,
  editable,
  updatePlayer,
  navBase,
}) => {
  const [page, setPage] = useState();
  const [pageSize, setPageSize] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [sortField, setSortField] = useState();
  const [filters, setFilters] = useState();

  const tableChange = (pagination, filters, sorter, columns) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
    setFilters(JSON.stringify(filters));
    setSortOrder(sorter.order);
    setSortField(sorter.field);
  };

  return (
    <InternalPlayerTable
      playerData={playerData}
      columns={columns}
      loading={loading}
      rowSelection={rowSelection}
      editable={editable}
      updatePlayer={updatePlayer}
      navBase={navBase}
      page={page}
      pageSize={pageSize}
      sortOrder={sortOrder}
      sortField={sortField}
      filters={filters}
      tableChange={tableChange}
    />
  );
};

const InternalPlayerTable = ({
  playerData,
  columns,
  loading,
  rowSelection,
  editable,
  updatePlayer,
  navBase,
  page,
  pageSize,
  sortOrder,
  sortField,
  filters,
  tableChange,
  expandable,
}) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");

  const isEditing = record => record.key === editingKey;

  const edit = record => {
    form.setFieldsValue(record);
    setEditingKey(record.key);
  };

  const saveRow = async record => {
    try {
      const newData = await form.validateFields();
      updatePlayer({ ...record, ...newData });
      setEditingKey("");
      message.success(record.playerName + " updated!", 2);
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  let modColumns = columns
    .map(column => {
      return {
        ...column,
        sortDirections: ["ascend", "descend"],
        sorter: sorter(column.dataIndex),
        sortOrder:
          column.dataIndex === (sortField || "playerName")
            ? sortOrder || "ascend"
            : false,
      };
    })
    .map(column => {
      return !column.filterable
        ? column
        : {
            ...column,
            filtered: filters && filters[column.dataIndex] != null,
            filteredValue: filters ? filters[column.dataIndex] : null,
            filters: getFilterValuesFromData(playerData, column.dataIndex),
            onFilter: (val, rec) => rec[column.dataIndex] === val,
          };
    })
    .map(column => {
      return !column.editable
        ? column
        : {
            ...column,
            onCell: record => ({
              record,
              title: column.title,
              dataIndex: column.dataIndex,
              editing: isEditing(record),
            }),
          };
    })
    .map(column => {
      return {
        ...column,
        title: lookupStatLabel(column.title),
      };
    })
    .map(column => {
      if (column.dataIndex === "playerName") {
        return {
          ...column,
          render: (_, p) => (
            <Link to={`${navBase}/players/${p.playerId}`}>{p.playerName}</Link>
          ),
        };
      } else {
        return column;
      }
    })
    .map(column => {
      if (column.title && column.title.startsWith("display")) {
        return { ...column, title: column.title.substring(7) };
      } else {
        return column;
      }
    })
    .map(column => {
      if (column.title && column.title.toLowerCase().includes("hudl")) {
        return {
          ...column,
          render: (_, p) => {
            if (p.link)
              return (
                <a href={p.link} target="_blank" rel="noopener noreferrer">
                  {p.linkType}
                </a>
              );
            else return "";
          },
        };
      } else {
        return column;
      }
    });

  if (editable) {
    modColumns.push({
      dataIndex: "operation",
      render: (_, record) => {
        if (isEditing(record)) {
          return (
            <Space>
              <Button
                shape="circle"
                icon={<CheckOutlined />}
                onClick={() => saveRow(record)}
              />
              <Button
                shape="circle"
                icon={<CloseOutlined />}
                onClick={() => setEditingKey("")}
              />
            </Space>
          );
        } else {
          return (
            <Button
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => edit(record)}
            />
          );
        }
      },
    });
  }

  const displayData = !playerData ? [] : playerData;

  return (
    <Form form={form} component={false}>
      <Content style={{ marginLeft: 0, marginRight: 30, marginTop: 15 }}>
        <Table
          rowSelection={rowSelection}
          dataSource={displayData}
          rowKey="playerId"
          columns={modColumns}
          loading={loading}
          onChange={tableChange}
          style={{ width: "100%" }}
          //scroll={{ y: 600, x: 4000 }}
          pagination={{
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            pageSize: pageSize ? pageSize : 20,
            current: page ? Number(page) : 1,
          }}
          size="small"
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          scroll={{ x: true }}
          expandable={expandable}
        ></Table>
      </Content>
    </Form>
  );
};

const PlayerTable = ({
  playerData,
  columns,
  loading,
  rowSelection,
  editable,
  updatePlayer,
  navBase,
  useStateUpdate,
  expandable
}) => {
  if (useStateUpdate) {
    return (
      <PlayerTableWithStateUpdate
        playerData={playerData}
        columns={columns}
        loading={loading}
        rowSelection={rowSelection}
        editable={editable}
        updatePlayer={updatePlayer}
        navBase={navBase}
      />
    );
  } else {
    return (
      <PlayerTableWithLocationUpdate
        playerData={playerData}
        columns={columns}
        loading={loading}
        expandable={expandable}
        rowSelection={rowSelection}
        editable={editable}
        updatePlayer={updatePlayer}
        navBase={navBase}
      />
    );
  }
};

export default PlayerTable;
