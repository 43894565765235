import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Space,
  Table,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { EditableCell, sorter } from "../../shared-components/PlayerTable";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "@reach/router";
import { parseQueryString } from "../../shared-components/queryStringUtils";
import {
  createScoutingTeam,
  deleteScoutingTeam,
  getScoutingTeams,
  selectDataBySeasonId,
  selectDataLoading,
  updateScoutingTeam,
} from "../../redux/scoutingteams_slice";
import { SeasonsSelect } from "../../shared-components/seasonsSelect";

const NewScoutingRoster = ({ isVisible, onHandle, seasonId, sport }) => {
  const dispatch = useDispatch();
  const [teamName, setTeamName] = useState();

  const okCancel = ok => () => {
    if (ok && teamName && teamName.trim() !== "")
      dispatch(createScoutingTeam(teamName, seasonId, sport));

    setTeamName(null);
    onHandle();
  };

  return (
    <Modal
      title="New Scouting Team"
      visible={isVisible}
      onOk={okCancel(true)}
      onCancel={okCancel(false)}
      destroyOnClose={true}>
      <h3>Team Name:</h3>
      <Input
        placeholder={"Team Name"}
        onChange={e => setTeamName(e.target.value)}
        autoFocus
        onPressEnter={okCancel(true)}
      />
    </Modal>
  );
};

const Scouting = props => {
  const location = useLocation();
  const { seasonId } = parseQueryString(location.search);
  const rosterTeamData = useSelector(selectDataBySeasonId(seasonId));
  const loadingTeams = useSelector(selectDataLoading);

  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const hideModal = () => setModalVisible(false);

  const edit = record => {
    form.setFieldsValue(record);
    setEditingKey(record.rosterId);
  };

  const saveRow = async record => {
    try {
      const newData = await form.validateFields();
      dispatch(updateScoutingTeam(seasonId, record.rosterId, newData.teamName));
      setEditingKey("");
      message.success("Scouting Team name updated!", 2);
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  useEffect(() => {
    if (!rosterTeamData && !loadingTeams && seasonId) {
      dispatch(getScoutingTeams(seasonId));
    }
  });

  const teamData = rosterTeamData
    ? rosterTeamData.map(r => {
        return {
          ...r,
          scoutLink: (
            <Link
              to={`../analytics?rosterId=${r.rosterId}&seasonId=${seasonId}&hideRosters=true&showAddGame=true&allowGameEdit=true`}>
              {r.teamName}
            </Link>
          ),
        };
      })
    : [];

  const columns = [
    {
      title: "Team Name",
      dataIndex: "scoutLink",
      sorter: sorter("teamName"),
      sortDirections: ["ascend", "descend"],
      onCell: record => ({
        record,
        title: "Team Name",
        dataIndex: record.rosterId === editingKey ? "teamName" : "scoutLink",
        editing: record.rosterId === editingKey,
      }),
    },
    {
      dataIndex: "rosterId",
      render: (rosterId, record) => {
        if (rosterId === editingKey) {
          return (
            <Space>
              <Button
                shape="circle"
                icon={<CheckOutlined />}
                onClick={() => saveRow(record)}
              />
              <Button
                shape="circle"
                icon={<CloseOutlined />}
                onClick={() => setEditingKey("")}
              />
            </Space>
          );
        } else {
          return (
            <Space>
              <Button
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => edit(record)}
              />
              <Button
                shape="circle"
                icon={<DeleteOutlined />}
                onClick={() => dispatch(deleteScoutingTeam(seasonId, rosterId))}
              />
            </Space>
          );
        }
      },
    },
  ];

  return (
    <Layout>
      <Layout.Content style={{ marginLeft: 0, marginRight: 30, marginTop: 15 }}>
        <h1>Scouted Teams</h1>
        <SeasonsSelect sport={"fb"} seasonId={seasonId} />
        <Button type={"primary"} onClick={() => setModalVisible(true)}>
          New Scouting Team
        </Button>
      </Layout.Content>
      <Layout.Content style={{ marginLeft: 0, marginRight: 30, marginTop: 15 }}>
        <Col xs={24} lg={18}>
          <Form form={form} component={false}>
            <Table
              dataSource={teamData}
              rowKey="rosterId"
              columns={columns}
              loading={loadingTeams}
              pagination={false}
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
            />
          </Form>
        </Col>
      </Layout.Content>
      <NewScoutingRoster
        isVisible={modalVisible}
        onHandle={hideModal}
        seasonId={seasonId}
        sport="FB"
      />
    </Layout>
  );
};

export default Scouting;
