import { Table, Layout, Radio } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getPlayerContribution,
  selectPlayerContributionByPlayerIdAndSeasonId,
  selectPlayerContributionDataLoading,
} from "../../redux/playerContribution_slice";
import { useEffect, useState } from "react";
import roundToTwo from "../../utils/roundToTwo";
import {
  lookupStatLabel,
  getStatGroupByStatKey,
} from "../../shared-components/categories_fb";

const PlayerContribution = ({ playerId, seasonId }) => {
  const dispatch = useDispatch();

  const [fieldsFilter, setFieldsFilter] = useState(null);

  const playerContributionLoading = useSelector(
    selectPlayerContributionDataLoading,
  );

  const playerContributionData = useSelector(
    selectPlayerContributionByPlayerIdAndSeasonId(playerId, seasonId),
  );

  useEffect(() => {
    if (
      playerId &&
      seasonId &&
      !playerContributionLoading &&
      !playerContributionData
    ) {
      dispatch(getPlayerContribution(playerId, seasonId));
    }
  }, [playerId, seasonId]);

  const transformContributionData = data => {
    try {
      return Object.keys(data.player_stats[0])
        .filter(key => data.player_stats[0][key])
        .map(key => {
          return {
            fieldName: key,
            group: getStatGroupByStatKey(key) || "other",
            player_total: data.player_stats[0][key],
            team_total: data.team_stats[0][key],
            player_percent: roundToTwo(
              (100 * data.player_stats[0][key]) / data.team_stats[0][key],
            ),
            team_total2: data.team_stats2[0][key],
            player_percent2: roundToTwo(
              (100 * data.player_stats[0][key]) / data.team_stats2[0][key],
            ),
          };
        });
    } catch (e) {
      return null;
    }
  };

  const data = transformContributionData(playerContributionData);

  const columns = [
    {
      title: "",
      dataIndex: "fieldName",
      // width: 300,
      fixed: true,
      render: value => lookupStatLabel(value),
    },
    {
      title: "Player Total",
      dataIndex: "player_total",
      // width: 300,
      fixed: true,
    },
    {
      title: "Team Total (played games)",
      dataIndex: "team_total",
      // width: 300,
      fixed: true,
    },
    {
      title: "Player Contribution (played games)",
      dataIndex: "player_percent",
      // width: 300,
      fixed: true,
      render: value => `${value}%`,
    },
    {
      title: "Team Total (full season)",
      dataIndex: "team_total2",
      // width: 300,
      fixed: true,
    },
    {
      title: "Player Contribution (full season)",
      dataIndex: "player_percent2",
      // width: 300,
      fixed: true,
      render: value => `${value}%`,
    },
  ];

  const onFieldsFilter = event => {
    if (event.target.value === fieldsFilter) {
      setFieldsFilter(null);
    } else {
      setFieldsFilter(event.target.value);
    }
  };

  return (
    <Layout.Content
      style={{
        marginLeft: 30,
        marginRight: 30,
        marginBottom: 10,
      }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
        }}>
        <div>
          <h1>Player Contribution</h1>
        </div>
        <div>
          <Radio.Group value={fieldsFilter} buttonStyle="solid">
            <Radio.Button onClick={onFieldsFilter} value="offense">
              Offense
            </Radio.Button>
            <Radio.Button onClick={onFieldsFilter} value="defense">
              Defense
            </Radio.Button>
          </Radio.Group>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={
          fieldsFilter
            ? data?.filter(item =>
                ["other", fieldsFilter].includes(item?.group),
              )
            : data
        }
        pagination={false}
        tableLayout="fixed"
        loading={playerContributionLoading}
        size="small"
      />
    </Layout.Content>
  );
};

export default PlayerContribution;
