import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProspect,
  getProspects,
  selectProspectList,
  selectProspectListLoading,
  updateProspect,
} from "../../redux/prospect_slice";
import { Button, Col, Form, Input, Layout, Modal, Popconfirm, Space } from "antd";
import PlayerTable from "../../shared-components/PlayerTable";
import { SeasonsSelect } from "../../shared-components/seasonsSelect";
import { useLocation } from "@reach/router";
import { parseQueryString } from "../../shared-components/queryStringUtils";
import { PlayerSelect } from "../../shared-components/PlayerSelect";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  selectCoachData,
  selectCoachDataLoading,
  selectSchoolProfile,
  selectSchoolProfilesLoading,
} from "../../redux/selectors/schoolProfilesSelectors";
import {
  getCoachData,
  getSchoolProfile,
} from "../../redux/actions/schoolProfileActions";

const SelectPlayerToAdd = ({ isVisible, onHandle, seasonId }) => {
  const [selectedPlayer, setSelectedPlayer] = useState(null);

  return (
    <Modal
      title="Select player to add to Prospect List"
      visible={isVisible}
      onOk={() => onHandle(selectedPlayer)}
      onCancel={() => onHandle(null)}
      destroyOnClose={true}
    >
      <Space direction="vertical">
        <PlayerSelect
          onChange={setSelectedPlayer}
          seasonId={seasonId}
          width={200}
        />
        <i>
          Note: adding a player to the prospect list implies there is consent to
          share player info.
        </i>
      </Space>
    </Modal>
  );
};

const ReferralLinks = ({
  isVisible,
  onHandle,
  playerIds,
  playerData,
  schoolData,
}) => {
  const location = useLocation();
  const links = playerIds.map(pid => {
    const p = playerData.find(p => p.playerId === pid);
    return `${p.playerName} (${p.position} - ${p.graduationYear})\n${location.origin}/referrals/players/${pid}`;
  });

  let msg = "";
  if (schoolData)
    msg = `${schoolData.schoolName} (${schoolData.state}) has shared player profiles through Playmaker Recruiting with you. Check them out!`;

  return (
    <Modal
      title="Referral Links"
      visible={isVisible}
      onCancel={() => onHandle()}
      okText="Copy"
      onOk={() =>
        navigator.clipboard
          .writeText(msg + "\n\n" + links.join("\n\n"))
          .then(onHandle())
      }
      destroyOnClose={true}
    >
      <Space direction="vertical">
        <p>{msg}</p>
        {links.map((l, index) => (
          <p key={`referral-links-${index}`}>{l}</p>
        ))}
      </Space>
    </Modal>
  );
};

const EditPlayer = ({
  isVisible,
  onUpdate,
  onCancel,
  playerId,
  playerData,
}) => {
  const [form] = Form.useForm();
  form.setFieldsValue(playerData);
  return (
    <Modal
      visible={isVisible}
      title="Update Prospect"
      okText="Update"
      cancelText="Cancel"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            form.resetFields();
            onUpdate(values);
          })
          .catch(info => {
            console.log("Validate Failed:", info);
          });
      }}
      destroyOnClose={true}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={playerData}
      >
        <h2>{playerData ? playerData.playerName : null}</h2>
        <Form.Item
          name="gpa"
          label="GPA"
          rules={[
            {
              message: "Should be a number in the form of #.# or #.##",
              pattern: /^\d{1}\.?\d{1,2}$/,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="rank_pct"
          label="Class Rank (percent)"
          rules={[
            {
              message: "Should be a number in the form of ## or ##.##",
              pattern: /^\d{1,2}(\.\d{1,2})?$/,
            },
          ]}
        >
          <Input type="number" step="1" min="0" max="100" />
        </Form.Item>
        <Form.Item
          name="psat"
          label="PSAT"
          rules={[
            {
              message: "Should be a 3- or 4-digit number",
              pattern: /^\d{3,4}$/,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="sat"
          label="SAT"
          rules={[
            {
              message: "Should be a 3- or 4-digit number",
              pattern: /^\d{3,4}$/,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="act"
          label="ACT"
          rules={[
            {
              message: "Should be a 2-digit number",
              pattern: /^\d{2}$/,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="gameFilmLinks" label="Game Film Links">
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item name="coachNotes" label="Coach Notes">
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const ProspectList = props => {
  const location = useLocation();
  const { seasonId } = parseQueryString(location.search);
  const data = useSelector(selectProspectList(seasonId));
  const loading = useSelector(selectProspectListLoading);

  const coachData = useSelector(selectCoachData);
  const coachDataLoading = useSelector(selectCoachDataLoading);
  const schoolData = useSelector(selectSchoolProfile);
  const schoolDataLoading = useSelector(selectSchoolProfilesLoading);

  const dispatch = useDispatch();

  const [showAddPlayerModal, setShowAddPlayerModal] = useState(false);
  const [showEditPlayerModal, setShowEditPlayerModal] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [selectedPlayerData, setSelectedPlayerData] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showReferralModal, setShowReferralModal] = useState(false);

  const addPlayerToProspectList = seasonId => playerId => {
    if (!!playerId) dispatch(updateProspect(seasonId, playerId, { playerId }));

    setShowAddPlayerModal(false);
  };

  useEffect(() => {
    if (!loading && !data) {
      dispatch(getProspects(seasonId));
    }

    if (!coachData && !coachDataLoading) dispatch(getCoachData());

    if (coachData && coachData.schoolId && !schoolData && !schoolDataLoading) {
      dispatch(getSchoolProfile(coachData.schoolId));
    }
  });

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  const columns = [
    {
      title: "Player",
      dataIndex: "playerName",
    },
    {
      title: "Position",
      dataIndex: "position",
      filterable: true,
    },
    {
      title: "Graduating Class",
      dataIndex: "graduationYear",
      filterable: true,
    },
    {
      title: "Height",
      dataIndex: "displayHeight",
    },
    {
      title: "Weight",
      dataIndex: "weight",
    },
    {
      title: "Wingspan",
      dataIndex: "wingspan",
    },
    {
      dataIndex: "operation",
      render: (_, record) => {
        return (
          <Space>
            <Button
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => {
                setSelectedPlayer(record.playerId);
                setSelectedPlayerData(record);
                setShowEditPlayerModal(true);
              }}
            />
            <Popconfirm
              placement="top"
              title={() => (
                <p>Are you sure you want to delete this prospect?</p>
              )}
              onConfirm={() =>
                dispatch(deleteProspect(seasonId, record.playerId))
              }
              okText="Yes"
              cancelText="No"
            >
              <Button shape="circle" icon={<DeleteOutlined />} />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <Layout>
      <Layout.Content
        style={{
          display: "flex",
          direction: "row",
          justifyContent: "space-between",
          alignItems: "baseline",
          marginRight: 30,
        }}
      >
        <h1>Prospect List</h1>
        <SeasonsSelect sport={"fb"} seasonId={seasonId} />
      </Layout.Content>
      <Layout.Content>
        <Space direction="horizontal">
          <Button
            type="primary"
            onClick={() => setShowAddPlayerModal(true)}
            style={{
              marginBottom: 10,
              float: "left",
            }}
          >
            Add Prospect
          </Button>
          <Button
            type="primary"
            onClick={() => setShowReferralModal(true)}
            style={{
              marginBottom: 10,
              float: "left",
            }}
          >
            Get Referral Links
          </Button>
        </Space>
      </Layout.Content>
      <Col xs={24} lg={18}>
      <PlayerTable
        playerData={data}
        rowSelection={rowSelection}
        columns={columns}
        loading={loading}
        navBase={"/coaches"}
      />
      </Col>
      <SelectPlayerToAdd
        seasonId={seasonId}
        isVisible={showAddPlayerModal}
        onHandle={addPlayerToProspectList(seasonId)}
      />
      <ReferralLinks
        isVisible={showReferralModal}
        playerIds={selectedRowKeys}
        playerData={data}
        schoolData={schoolData}
        onHandle={() => setShowReferralModal(false)}
      />
      <EditPlayer
        isVisible={showEditPlayerModal}
        onCancel={() => setShowEditPlayerModal(false)}
        onUpdate={values => {
          dispatch(updateProspect(seasonId, selectedPlayer, values));
          setShowEditPlayerModal(false);
        }}
        playerId={selectedPlayer}
        playerData={selectedPlayerData}
      />
    </Layout>
  );
};

export default ProspectList;
