import { useLocation } from "@reach/router";
import { parseQueryString } from "../../shared-components/queryStringUtils";
import { SeasonsSelect } from "../../shared-components/seasonsSelect";
import React from "react";
import PlayerContribution from "./PlayerContribution";
import RosterRanks from "./RosterRanks";
import RosterRecords from "./RosterRecords";

const PlayerAnalytics = ({ playerId }) => {
  const location = useLocation();

  const { seasonId } = parseQueryString(location.search);

  return (
    <>
      <SeasonsSelect sport={"fb"} seasonId={seasonId} />
      <PlayerContribution playerId={playerId} seasonId={seasonId} />
        <RosterRanks playerId={playerId} seasonId={seasonId} />
        <RosterRecords playerId={playerId} seasonId={seasonId} />
    </>
  );
};

export default PlayerAnalytics;
