import { createSlice } from "@reduxjs/toolkit";
import { API } from "aws-amplify";
import * as queryString from "query-string";

const slice = createSlice({
  name: "rosterStatsData",
  initialState: {
    loading: false,
    updating: false,
    dataByPlayerId: {},
    error: null,
  },
  reducers: {
    rosterStatsDataLoading: state => {
      state.loading = true;
      state.error = null;
    },
    getRosterStatsSuccess: (state, action) => {
      state.loading = false;

      if (state.dataByPlayerId[action.payload.playerId]) {
        state.dataByPlayerId[action.payload.playerId] = {
          ...state.dataByPlayerId[action.payload.playerId],
          [action.payload.seasonId]: action.payload.data,
        };
      }
      else {
        state.dataByPlayerId = {
          ...state.dataByPlayerId,
          [action.payload.playerId]: {[action.payload.seasonId]: action.payload.data},
        };
      }

      state.error = null;
    },
    getRosterStatsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});
export default slice.reducer;

// Actions
const {
  rosterStatsDataLoading,
  getRosterStatsSuccess,
  getRosterStatsFailure,
} = slice.actions;

export const getRosterStats =
  (playerId, seasonId) =>
  async dispatch => {
    try {
      dispatch(rosterStatsDataLoading());
      console.log("getting roster stats");
      const response = await API.get(
        `playerAPI`,
        `roster-stats/${playerId}/${seasonId}`,
      );
      console.log("got roster stats", response);

      if (response.error) {
        dispatch(getRosterStatsFailure(response.error));
      } else {
        dispatch(getRosterStatsSuccess({ playerId, seasonId, data: response }));
      }
    } catch (e) {
      dispatch(getRosterStatsFailure(e));
    }
  };

export const selectRosterStatsDataLoading = store =>
  store.rosterStatsData.loading;

export const selectRosterStatsByPlayerIdAndSeasonId =
  (playerId, seasonId) => store => {
    try {
      return store.rosterStatsData.dataByPlayerId[playerId][seasonId];
    } catch (e) {
      return null;
    }
  };
