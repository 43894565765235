import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTeamGamestatsByRosterId,
  getTeamSeasonstatsByRosterId,
} from "../../../redux/actions/teamstatsActions";
import {
  selectTeamGamestatsByRosterIdGameId,
  selectTeamSeasonstatsByRosterId,
  selectTeamstatsLoading,
} from "../../../redux/selectors/teamstatsSelectors";
import { Card, Table } from "antd";
import roundToTwo from "../../../utils/roundToTwo";

const dataToTableMapping = [
  {
    key: "row1",
    data1: "Offense_TOT_YDS",
    name1: "Total Yards",
    data2: "Defense_TOT_YDS",
    name2: "Total Yards Allowed",
  },
  {
    key: "row2",
    data1: "Offense_PASS_YDS",
    name1: "Passing Yards",
    data2: "Defense_PASS_YDS",
    name2: "Passing Yards Allowed",
  },
  {
    key: "row3",
    data1: "Offense_RUSH_YDS",
    name1: "Rushing Yards",
    data2: "Defense_RUSH_YDS",
    name2: "Rushing Yards Allowed",
  },
  {
    key: "row4",
    data1: "Offense_PASS_TD",
    name1: "Passing TDs",
    data2: "Defense_SACK",
    name2: "Sacks",
  },
  {
    key: "row5",
    data1: "Offense_RUSH_TD",
    name1: "Rushing TDs",
    data2: "Defense_INT",
    name2: "INT",
  },
  {
    key: "row6",
    data1: "Offense_TO",
    name1: "Turnovers",
    data2: "Defense_TO",
    name2: "Turnovers Recovered",
  },
  {
    key: "row7",
    data1: "Offense_PTS_SCORED",
    name1: "Total Points Scored",
    data2: "Defense_PTS_SCORED",
    name2: "Total Points Allowed",
  },
];

const fillInRowData = (row, data) => {
  return { ...row, data1: data[row.data1], data2: data[row.data2] };
};

const TeamPerformance = ({ rosterId, gameId }) => {
  //const gameStats = useSelector(selectTeamGamestatsByRosterIdGameId(rosterId, gameId));
  const teamStats = useSelector(
    gameId
      ? selectTeamGamestatsByRosterIdGameId(rosterId, gameId)
      : selectTeamSeasonstatsByRosterId(rosterId),
  );
  const teamStatsLoading = useSelector(selectTeamstatsLoading);
  const haveData = teamStats && teamStats[0];

  const dispatch = useDispatch();

  const displayData = haveData
    ? dataToTableMapping.map(r => fillInRowData(r, teamStats[0]))
    : null;

  useEffect(() => {
    if (gameId && rosterId && !teamStatsLoading && !teamStats) {
      console.log("getting team game stats");
      dispatch(getTeamGamestatsByRosterId(rosterId));
    } else if (rosterId && !teamStats && !teamStatsLoading) {
      console.log("getting team season stats");
      dispatch(getTeamSeasonstatsByRosterId(rosterId));
    }
  });

  console.log("teamstats", teamStats);
  const columns = [
    {
      title: "Total Offense" + (!gameId ? " (avg per game)" : ""),
      dataIndex: "name1",
      width: 200,
      // fixed: true,
    },
    {
      dataIndex: "data1",
      width: 100,
      render(text, render) {
        return <div>{roundToTwo(text)}</div>;
      },
    },
    {
      title: "Total Defense" + (!gameId ? " (avg per game)" : ""),
      dataIndex: "name2",
      width: 200,
      // fixed: true,
    },
    {
      dataIndex: "data2",
      width: 100,
      // fixed: true,
      render(text, render) {
        return <div>{roundToTwo(text)}</div>;
      },
    },
  ];

  return (
    <Card title={"Team Performance"} style={{ marginBottom: "10px" }}>
      <Table
        columns={columns}
        dataSource={displayData}
        pagination={false}
        tableLayout="fixed"
        scroll={{
          x: 350
        }}
        loading={teamStatsLoading}
        size="small"
      />
    </Card>
  );
};

export default TeamPerformance;
