import React, { useEffect } from "react";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSeasonsBySport,
  selectSeasonsLoading,
} from "../redux/season_slice";
import * as queryString from "query-string";
import { useLocation, useNavigate } from "@reach/router";
import { getSeasonsBySport } from "../redux/season_slice";

const { Option } = Select;

const buildOptionsArray = seasonsData => {
  return seasonsData.map(season => (
    <Option value={season.seasonId} key={season.seasonId}>
      {season.name}
    </Option>
  ));
};

export const SeasonsSelect = ({ seasonId, sport, selectable = true }) => {
  const seasonsData = useSelector(selectSeasonsBySport(sport));
  const seasonOptions = seasonsData ? buildOptionsArray(seasonsData) : [];
  const loading = useSelector(selectSeasonsLoading);
  const navigate = useNavigate();
  const location = useLocation();

  const onChangeSeason = (seasonId, replace) => {
    let queryParams = queryString.parse(location.search);
    delete queryParams.rosterId;
    delete queryParams.hideRosters;
    navigate(
      location.pathname +
        "?" +
        queryString.stringify({ ...queryParams, seasonId }),
      { replace },
    );
  };

  const dispatch = useDispatch();

  let seasonName = null;
  try {
    seasonName = seasonsData.find(s => s.seasonId === seasonId).name;
  } catch (e) {}

  useEffect(() => {
    if (!seasonsData && !loading) dispatch(getSeasonsBySport(sport));

    // go to first season if none is selected:
    if (!seasonId && seasonsData && seasonsData.length > 0) {
      onChangeSeason(seasonsData[0].seasonId, true);
    }
  });

  if (selectable) {
    return (
      <div style={{ paddingBottom: 8 }}>
        <span>Select Season: </span>
        <Select value={seasonId} onChange={onChangeSeason} showArrow={true}>
          {seasonOptions}
        </Select>
      </div>
    );
  } else {
    return <div>Season: {seasonName}</div>;
  }
};
