import { Card, Table } from "antd";
import {useDispatch, useSelector} from "react-redux";
import {
  getRosterStats,
  selectRosterStatsByPlayerIdAndSeasonId,
  selectRosterStatsDataLoading,
} from "../../redux/rosterStats_slice";
import {useEffect} from "react";

const RosterRecords = ({ playerId, seasonId }) => {
  const dispatch = useDispatch();

  const rosterStatsLoading = useSelector(selectRosterStatsDataLoading);

  const rosterStatsData = useSelector(
      selectRosterStatsByPlayerIdAndSeasonId(playerId, seasonId),
  );

  useEffect(() => {
    if (playerId && seasonId && !rosterStatsLoading && !rosterStatsData) {
      dispatch(getRosterStats(playerId, seasonId));
    }
  }, [playerId, seasonId]);

  const transformData = (data) => {
    try {
      return Object.keys(data.roster_records[0])
          .filter(key => data.roster_records[0][key])
          .map(key => {
            return {
              fieldName: key,
              fieldValue: data.roster_records[0][key],
            }
          });
    }
    catch (e) {
      return null;
    }
  };

  const data = transformData(rosterStatsData);

  const columns = [
    {
      title: "",
      dataIndex: "fieldName",
      // width: 300,
      fixed: true,
    },
    {
      title: "Counts",
      dataIndex: "fieldValue",
      // width: 300,
      fixed: true,
    },
  ];
  return (
    <Card style={{ marginBottom: "10px", height: "100%" }}>
      <h1>Roster Records</h1>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        tableLayout="fixed"
        loading={rosterStatsLoading}
        size="small"
      />
    </Card>
  );
};

export default RosterRecords;
