import React from "react";
import { parseQueryString } from "../../shared-components/queryStringUtils";
import RosterTabs from "./components/RosterTabs";
import TeamPerformance from "./components/TeamPerformance";
import { Card, Col, Layout, Row } from "antd";
import TeamSchedule from "./components/TeamSchedule";
import { PlayerPositionGroupTabs } from "./components/playerPositionGroupTabs";
import CoachTeamPerformanceCapabilities from "./components/CoachTeamPerformanceCapabilities";

const Dashboard = props => {
  const { rosterId } = parseQueryString(props.location.search, "rosterId");

  return (
    <div>
      <Layout>
        <Layout.Content>
          <RosterTabs
            rosterId={rosterId}
            hideInactive={true}
            hideScouting={true}
          />
          <Row>
            <Col xs={24} lg={18}>
              <Card style={{ marginBottom: "10px" }}>
                <TeamSchedule rosterId={rosterId} />
              </Card>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col xs={24} lg={12}>
              {/* <Card style={{ marginBottom: "10px" }}> */}
              <TeamPerformance rosterId={rosterId} />
              {/* </Card> */}
            </Col>
            <Col xs={24} lg={12}>
              <Card title={"Depth Chart"} style={{ marginBottom: "10px" }}>
                <PlayerPositionGroupTabs rosterId={rosterId} readOnly={true} />
              </Card>
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
    </div>
  );
};

export default Dashboard;
